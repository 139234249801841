import * as React from 'react';
import ReactGA from 'react-ga';
import ToolTips from '../../Common/ToolTips/toolTips';

type ScoreTextMetricProps = {
    id: number,
    text: string,
    tooltip: string,
    grandparentClicked: boolean,
    handleGrandparentClicked: Function,
    editMode: boolean,
    onInputChange: Function,
    onDelete: Function,
}

export default class ScoreTextMetric extends React.PureComponent<ScoreTextMetricProps,{tipToggled:boolean}> {
  constructor(props: ScoreTextMetricProps) {
    super(props);

    this.state = {
      tipToggled: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick(e: React.MouseEvent, value: boolean){
    e.stopPropagation();
    this.setState({ tipToggled: value });
  }

  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public componentDidUpdate(){
    if (this.props.grandparentClicked){
      this.setState({ tipToggled: false });
    }
  }
  
  public render() {
    const { text, tooltip, editMode, onInputChange, onDelete, id } = this.props; 

    return (
        
      <div className={`score-text-metric ui-toolkit ${editMode ? 'edit' : ''}`}>
        <div>
          {
            editMode ? 
              <div className='score-text-metric ui-toolkit container'>
                <input type="text"
                  className='metric-sequence'
                  value={id} 
                  onChange={(e) => onInputChange(e.target.value, 'id')}/>
                <input type="text"
                  placeholder="Text"
                  value={text} 
                  onChange={(e) => onInputChange(e.target.value, 'text')}/>
              </div>

              : 
              text
          } </div>
        <div className ="toolTip-container">
          {editMode || tooltip !== '' ?
            <ToolTips
              handleParentClick={this.handleClick}
              handleGrandparentClick={this.props.handleGrandparentClicked}
              tipToggled={editMode ? true : this.props.grandparentClicked? false : this.state.tipToggled}
              text = {tooltip} 
              onInputChange={(value: string, fieldName: string) => onInputChange(value, fieldName)} 
              editMode={editMode}
            /> : null}
        </div>
        {
          editMode &&
          <button onClick={() => onDelete()}><img src='/assets/cross.png' /></button>
        }
      </div>
    );
  }
}
