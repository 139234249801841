import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as qs from 'qs';
import UserSummary from './UserSummary/userSummary';
import { AreaSummary } from './AreaSummary/areaSummary';
import Page from '../../uiToolkit/containers/page';
import { SuggestionItem } from './SuggestionItem/suggestionItem';
import { ApplicationState } from '../../store';
import * as AuthActions from '../../store/authentication/actions';
import * as ScoreActions from '../../store/score/actions';
import CronDateDropdown from '../Common/CronDateDropdown/cronDateDropdown';
import Message from '../Common/Message/message';
import ReactGA from 'react-ga';
import ScoreTrendChart from '../Common/TrendChart/ScoreTrendChart';
import { PieChart, Pie, Cell, Tooltip  } from 'recharts';
import SuggestionProgressBar from '../Common/SuggestionProgressBar/suggestionProgressBar';
import { getLatestValidDate } from '../../uiToolkit/helpers/cronHelper';
import { AuthManager } from '../../AuthManager';
import ToolTips from '../Common/ToolTips/toolTips';
import Loading from '../Common/Loading/loading';
import { WaveScore } from '../Common/WaveScore/waveScore';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  score: state.score,
});
const mapDispatch = {
  ...ScoreActions.actionCreators,
  ...AuthActions.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { scoreid?: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type Props =
  PropsFromRedux
  & PropsType
  & { scoreId?: string };

export enum suggestionStyle {
    Bad = 0,
    Good = 2
}

interface IState {
  reportingEntity: string;
  dateKey: string;
  isSummary:boolean;
  selectedSuggestionId?: string;
  tipToggled:boolean;
}

class ScoreCard extends React.PureComponent<Props,  IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      reportingEntity: (props.authentication && props.authentication.selectedReportingEntity) || '',
      dateKey: (props.authentication && props.authentication.selectedDateKey) || '',
      isSummary: true,
      tipToggled: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  public componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const {
      history, authentication, requestScore,
      setCurrentReportingEntity, setCurrentDate, scoreId, match,
    } = this.props;
    const { dateKey, reportingEntity } = this.state;

    const query = qs.parse(history.location.search.replace('?', '').toLowerCase());
    const tenantDetails = authentication && authentication.tenantDetails;

    let updateURL = false;
    let updateState = false;

    let newState = {};

    const queryDateKey: string = (query.datekey && query.datekey.toString()) || '';
    const stateDateKey: string = dateKey;
    
    // Manage the date state
    if (stateDateKey.length > 0) {
      // update query string
      if (queryDateKey !== stateDateKey) {
        updateURL = true;
      }
    } else if (queryDateKey.length > 0) {
      // set state correctly
      setCurrentDate(queryDateKey);
      newState = ({ ...newState, dateKey: queryDateKey });
      updateState = true;
    } else{
      const latestValidDate = getLatestValidDate(tenantDetails && tenantDetails.reportingWindow);
      this.setState({ dateKey: latestValidDate });
    }

    const stateEntityKey = reportingEntity.toLowerCase();
    const queryEntityKey =  (scoreId || match.params.scoreid || '').toLowerCase();

    // Manage the site data
    if (stateEntityKey.length > 0) {
      // update query string
      if (queryEntityKey !== stateEntityKey) {
        updateURL = true;
      }
    } else if (queryEntityKey.length > 0) {
      // set state correctly
      setCurrentReportingEntity(queryEntityKey);
      newState = ({ ...newState, reportingEntity: queryEntityKey });
      updateState = true;
    }

    if (updateState) {
      this.setState(newState);
    } else {
      if (updateURL) {
        history.replace(`/score/${stateEntityKey}/?datekey=${stateDateKey}`);
      }
      
      const authManager = new AuthManager();

      if(authManager.IsUserAuthenticated()) {
        // load data
        requestScore(stateDateKey, stateEntityKey);
      }
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: IState) {
    const { history, requestScore } = this.props;
    const { dateKey, reportingEntity } = this.state;

    const query = qs.parse(history.location.search.replace('?', '').toLowerCase());
    const queryDateKey: string = (query.datekey && query.datekey.toString()) || '';

    // current date key changed
    if (dateKey !== prevState.dateKey || reportingEntity !== prevState.reportingEntity) {
      // update query string
      if (queryDateKey !== dateKey) {
        history.replace(`/score/${reportingEntity}/?datekey=${dateKey}`);
      }

      const authManager = new AuthManager();

      if(authManager.IsUserAuthenticated()) {
        requestScore(dateKey, reportingEntity);
      }
    }
  }

  public dateChanged(dateKey: string) {
    const { setCurrentDate } = this.props;

    setCurrentDate(dateKey);
    this.setState({ dateKey });
  }

  public viewArea(scoreId: string, areaId: string, dateKey: string) {
    const { history } = this.props;
    history.push(`/score/${scoreId}/area/${areaId}?datekey=${dateKey}`);
  }

  public handleClick(e: { stopPropagation: () => void; }, value: boolean){
    e.stopPropagation();
    this.setState({ tipToggled: value });
  }
  
  public render() {
    const { authentication, score } = this.props;
    const { dateKey, reportingEntity, isSummary, selectedSuggestionId } = this.state;

    const userDetails = authentication;
    const userTenantDetails = userDetails && userDetails.tenantDetails;
    const userAreaDetails = (userDetails && userDetails.scoreAreas) || [];
    const scoreAreaLookup = (authentication && authentication.scoreAreaLookup) || {};

    const scoreDetails = score;
    const reportingEntityDetails = score 
    && userDetails.scoringEntityLookup 
    && userDetails.scoringEntityLookup[score.siteId];
    const areas = (score && score.scoreAreas) || [];
    const suggestions = (score && score.suggestions) || [];
    const threshold = (userTenantDetails && userTenantDetails.threshold) || 0;
 
    const trend = (score && score.trend && score.trend.scoreTrends) || [];
    const prevTrend = (score && score.prevTrend && score.prevTrend.scoreTrends) || [];
    
    const goodSuggestions = suggestions.filter(s => s.goodResult).sort((a, b) => b.weighting - a.weighting).slice(0,3);
    const badSuggestions = suggestions.filter(s => !s.goodResult).sort((a, b) => b.weighting - a.weighting).slice(0,2);
     
    const allSuggestions = goodSuggestions.concat(badSuggestions);
    const selectedSuggestion = selectedSuggestionId !== undefined && allSuggestions.find(s => s.id === selectedSuggestionId);

    const data = areas.map(d => { 
      return { 
        ...d, 
        area: scoreAreaLookup[d.area].name,
      };
    }); 

    const COLOURS = ['#F2CD00', '#dbc85b','#d8cb84','#e0c421'  ];

    return (
 
      <div className="score-card"
        onClick={(e) => this.handleClick(e, false)}>          
        <div className="stretch-bar" />
        <Page pageNumber={1}
          mode="score-card-1">
          {score?.isLoading && (<Loading/>)}
                  
          {!score?.isLoading && (               
            <div><div className="content">
              <div className="header">
                <div className="header-stack">
                  <UserSummary
                    companyImageLink={(userTenantDetails && userTenantDetails.logo) || ''}
                    backgroundImageLink="/assets/Background.png"
                    scoreId={reportingEntity}
                    companyName={userTenantDetails ? userTenantDetails.name : ''}
                    scoreName={reportingEntityDetails && reportingEntityDetails.name ? reportingEntityDetails.name : ''}
                    ownerName={reportingEntityDetails && reportingEntityDetails.owner.name ? reportingEntityDetails.owner.name : ''}
                  />
                </div>
              </div>            
              <div className="card-wrapper">
                <div className={`summary-card ${isSummary ? 'primary' : ''}`}>
                  <div className="tootipAndWaveScore">
                    <div className = "box"></div>
                    <div className= "waveScore">
                      <WaveScore score={(scoreDetails && scoreDetails.rawScore) || 0}
                        maxScore={5}
                        index={1} />
                    </div>
                    <div className= "toolTip-container">
                      <ToolTips
                        handleParentClick={this.handleClick}
                        tipToggled={this.state.tipToggled}
                        text = {'Your Orderly Score is calculated using a proprietary algorithm, which compares your financial ' + 
                    'performance and environmental impact to create scores across a range of business areas. ' +
                    'These are then combined to give you a final Orderly Score. '}
                        onInputChange={() => null}
                      />
                    </div>
                  </div>
                  <div className="suggestions">
                    {allSuggestions.map((suggestion) => (
                      <SuggestionItem
                        key={suggestion.id}
                        suggestion={suggestion}
                        scoreArea = {scoreAreaLookup[suggestion.areaName]}
                        clickEvent={() => this.setState({ selectedSuggestionId: suggestion.id })}
                      />
                    ))}
                  </div> 
                  
                  {suggestions.length === 0 ? <Message text="No Data Available" /> : null}
                  {isSummary ? (
                    <button className="toggle-button"
                      type="button"
                      onClick={() => this.setState({ isSummary: false, selectedSuggestionId: undefined })}>
                    Full Scorecard
                      {' '}
                      <div className="chevron" />
                    </button>
                  )
                    : (
                      <button className="toggle-button"
                        type="button"
                        onClick={() => this.setState({ isSummary: true })}>
                      Suggestions
                        {' '}
                        <div className="chevron" />
                      </button>
                    )}
                </div>

                <div className={`full-card ${isSummary ? '' : 'primary'}`}>
                  <div className="header">
                    <div className="header-stack">
                      <div className="header-controls">
                        <div className="header-wrapper">
                          <div className="date-selector">
                            <CronDateDropdown
                              placeholderText="Select date range"
                              cronString={(userTenantDetails && userTenantDetails.reportingWindow) || ''}
                              currentDateKey={dateKey}
                              isGrey
                              onChange={(dk: string) => this.dateChanged(dk)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="title">Key Improvement Areas</div>
                  </div>
                  <div className="sub-card">
                    <WaveScore
                      score={(scoreDetails && scoreDetails.rawScore) || 0}
                      maxScore={5}
                      index={2}
                    />
                  </div>
                  <div className="area-card">
                    <div className="title">Full Breakdown</div>
                    {userAreaDetails.sort((a, b) => a.sequence - b.sequence).map((area, idx) => {
                      const scoreArea = areas.find((m) => m.area === area.dataId);
                      if (!scoreArea) return null;
                      return (
                        <AreaSummary
                          key={area.dataId}
                          score={scoreArea.rawScore}
                          index={idx}
                          maxScore={5.0}
                          detailClick={() => this.viewArea(reportingEntity, area.dataId, dateKey)}
                          title={area.name}
                          onStreak={scoreArea.onStreak}
                          backgroundImagePath={area.image}
                        />
                      );
                    })}

                    {suggestions.length === 0 ? <Message text="No Data Available" /> : null}
                    {isSummary ? (
                      <button className="toggle-button"
                        type="button"
                        onClick={() => this.setState({ isSummary: false, selectedSuggestionId: undefined })}>
                      Full Scorecard
                        {' '}
                        <div className="chevron" />
                      </button>
                    )
                      : (
                        <button className="toggle-button"
                          type="button"
                          onClick={() => this.setState({ isSummary: true })}>
                        Suggestions
                          {' '}
                          <div className="chevron" />
                        </button>
                      )}
                  </div>
                </div>
              </div>

              {/* //selected suggestion page */}
              {
                <div className={`suggestion-wrapper ${selectedSuggestion ? 'open' : ''}`}>
                  <div className={`column-left ${selectedSuggestion ? 'open' : ''}`}>
                    <div className="suggestion-title left">
                      <div>
                        <div className="grey-color">Current</div>
                        <div>Suggestion...</div>
                      </div>
                    </div>
                    <div className="current-suggestion">
                      <div className="selected-suggestion-wrapper">
                        <div className={`selected-header selected-header-${selectedSuggestion && selectedSuggestion.goodResult? suggestionStyle.Good : suggestionStyle.Bad }`}>
                          {selectedSuggestion && selectedSuggestion.message}</div>
                        <div className="text-wrapper">
                          <div className="bold-text">How can I improve?</div>
                          <div className="selected-text">{selectedSuggestion && selectedSuggestion.suggestion}</div>
                          <div className="bold-text">Why does this matter?</div>
                          <div className="selected-text">{selectedSuggestion && selectedSuggestion.explanation}</div>
                          <div> 
                            {selectedSuggestion && selectedSuggestion.sustainable ? 
                              <img className="leaf-icon"
                                src="/assets/leaf.svg"
                                alt="sustainable leaf icon"
                                title= "Sustainable Suggestion">
                              </img>
                              : null}
                          </div> 
                        </div>
                      </div>
                    </div>
                    { selectedSuggestion && <div className="progress-wrapper">
                      <div className="progress-title">See your progress and how you compare...</div>
                      <SuggestionProgressBar 
                        suggestion = {selectedSuggestion}
                      />
                    </div> }
                  </div>
                  <div className={`column-right ${selectedSuggestion ? 'open' : ''}`}>
                    { selectedSuggestion && <div className="pie-chart">
                      <div className="pie-title">Your perfomance in each area...</div>
                      <PieChart width={550}
                        height={400}
                      >
                        <Pie data={data}
                          dataKey={(v) => Math.floor(v.rawScore * 100)  /100}
                          nameKey="area"
                          cx={280}
                          cy={225}
                          outerRadius={125}
                          key={selectedSuggestion.animationId}
                          label={(entry) => entry.name}
                        >
                          {
                            data.map((entry, index) => <Cell key={`cell-${index}`}
                              fill={COLOURS[index % COLOURS.length]} />)
                          }
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div> }
                    <div className="suggestion-title">
                      <div>
                        <div className="grey-color">Other</div>
                        <div>Suggestions...</div>
                      </div>
                    </div>
                    <div className="suggestion-container">
                      <div className="suggestions">
                        <div className="suggestions list-suggestions">
                          {allSuggestions.map((suggestion) => (
                            suggestion.id !== selectedSuggestionId?
                              <SuggestionItem
                                key={suggestion.id} 
                                suggestion = {suggestion}
                                scoreArea = {scoreAreaLookup[suggestion.areaName]}
                                clickEvent={() => {
                                  this.setState({ selectedSuggestionId: suggestion.id });
                                }}
                              />
                              : null
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <button className={`collapse-button ${selectedSuggestion ? 'show' : ''}`}
                type="button"
                onClick={() => this.setState({ selectedSuggestionId: undefined })}
              ><img src='/assets/collapse-arrow.png'/></button>
            </div> 

            <div className="bonus-content">
              <div className="wrapper">
                <div className="bar">
                  <div className="stack">
                    <div className="trend">
                      <div className="bar title">
                        <div className="stack">
                          <div>Trend</div>
                          <div>Analysis.</div>
                        </div>
                        <svg viewBox="0,0 80,40">
                          <path d="M 0,0
                        L 20,0
                        A 2.5,2.5 0 1,0 60,0
                        L 80,0
                        A 12.5,12.5 0 1,1 0,0
                        "
                          />
                        </svg>
                      </div>
                      <div>After your score has been calculated for a while, you will start to see trend data and useful stats here.</div>
                    </div>
                    <div className="bar">
                      <div className="stack badge">
                        <div><img src="/assets/score-card/shield.svg"
                          alt="shield" /></div>
                        <div className="title">Waste Warrior?</div>
                        <div>Do you know how much waste you have saved?</div>
                      </div>
                      <div className="stack badge">
                        <div><img src="/assets/score-card/process.svg"
                          alt="gear" /></div>
                        <div className="title">Perfect Process?</div>
                        <div>Do you regularly complete your processes on time, ensuring correct system data?</div>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className = "trend-chart">
                      <ScoreTrendChart
                        scoreTrend = {trend.concat(prevTrend)}
                        threshold = {threshold}
                      />
                    </div>
                  </div>
                </div>
                <div className="bar">
                  <div><img src="/assets/score-card/phone.png"
                    alt="phone" /></div>
                  <div className="mobile">
                    <div className="title">Take Orderly on the Go</div>
                    <div className="description">Access your Orderly score and all administration features on the go.</div>
                    <ul>
                      <li>View your live score and suggestions</li>
                      <li>Drill into your metrics</li>
                      <li>See your place on the leaderboard</li>
                      <li>Increase sustainability in your organisation</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div></div>)}  
        </Page>
      </div>

    );
  }
}

export default connector(withRouter(ScoreCard));
