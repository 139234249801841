import * as React from 'react';
interface IProps {
    children?: React.ReactNode;
    title: string;
    inProgress: boolean;
    continue: Function;
    cancel: Function;
    useConfirmButton?: boolean;
    useCancelButton?: boolean;

}

export default class Modal extends React.PureComponent<IProps, {}> {
  public render() {
    const {
      title, children, inProgress, continue: contiueFunc, cancel, useConfirmButton=true, useCancelButton=true,
    } = this.props;

    return (
      <div className="modal-blocker ui-toolkit">
        <div className="modal">
          <form>
            <div className="modal-title">
              <h2>{title}</h2>
            </div>
            <div className="modal-body">
              {children}
            </div>
            <div className="modal-buttons">
              {useConfirmButton && <button
                type="button"
                className="action"
                disabled={inProgress}
                onClick={() => contiueFunc()}
              >
                Confirm
              </button>
              }
              {useCancelButton && <button
                type="button"
                className="action negative"
                disabled={inProgress}
                onClick={() => cancel()}
              >
                Cancel
              </button>
              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}
