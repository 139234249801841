import * as Cron from 'cron-parser';
import moment from 'moment';

export const getLatestValidDate = (cronString?: string) : string => {
  const today = new Date();

  if (!cronString) {
    return moment(today).format('YYYY-MM-DD');
  }

  const cron = Cron.parseExpression(cronString, { currentDate: today });

  return moment(cron.prev().toString()).format('YYYY-MM-DD');
};
