import * as React from 'react';
import { ScoreArea } from '../../../store/authentication/types';
import { Suggestion } from '../../../store/score/types';

import ReactGA from 'react-ga';

export enum suggestionStyle {
    Bad = 0,
    Good = 2
}

interface IProps{
    suggestion: Suggestion,
    scoreArea: ScoreArea,
    clickEvent: Function
    
}


export class SuggestionItem extends React.PureComponent< IProps, {}> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  public render() {
    const { goodResult, message,  sustainable } = this.props.suggestion;
    const { name } = this.props.scoreArea;
    const { clickEvent } = this.props;
    

    return (
      <button 
        className="suggestion-item ui-toolkit"
        type= 'button'
        onClick={() => clickEvent()}
      >
        <div className={`suggestion-score suggestion-score-${goodResult? '2' : '0'}`}>
          <svg viewBox="0,0 80,40">
            <path d={`M 0,0 
                        L 20,0  
                        A 2.5,2.5 0 1,0 60,0
                        L 80,0
                        A 12.5,12.5 0 1,1 0,0
                        `}
            />
          </svg>
        </div>
       
        <div className="suggestion-content">
          <div className= "suggestion-link">
            {message}
            <span className={`area area-${goodResult ? '2' : '0'}`}>
              {` (${name})`}
            </span>
          </div>
          <div> 
            {sustainable ? 
              <img className="leaf-icon"
                src="/assets/leaf.svg"
                alt="sustainable leaf icon"
                title= "Sustainable Suggestion">
              </img>
              : null}
          </div> 
        </div>
      </button>
    );
  }
}

