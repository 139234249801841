import * as React from 'react';
import ReactGA from 'react-ga';
import { withRouter, RouteComponentProps } from 'react-router';
import { Avatar } from '../../Common/Avatar/avatar';

interface propTypes {
    score: number,
    maxScore: number,
    companyImageLink: string,
    owner:string,
    name: string
}
type TParams = { scoreid: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }

type IProps =
    propTypes
    & PropsType;

class ScoreCardSummaryBar extends React.PureComponent<IProps, {}> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
    private radius: number = 25; // 131;

    private centerX: number = this.radius;

    private centerY: number = 0;

    private angle: number = 0;

    public render() {
      const {
        maxScore, score, companyImageLink,
        name, owner,
        match: {
          params: {
            scoreid,
          },
        },

      } = this.props;

      this.angle = (((maxScore - score) / maxScore)) * (Math.PI);

      return (
        <div className="score-card-summary-bar ui-toolkit">
          <div className="score-card-wrapper">
            <div className="detail-holder">
              <div className="image-holder">
                <Avatar companyImageLink={companyImageLink}
                  label={scoreid} />
              </div>
              <div>
                <div className="score">
                  <div className="score-values">
                    <div className="current-score">{score.toFixed(1)}</div>
                    <div className="total-score">
                      /
                      {maxScore}
                    </div>
                  </div>

                  {score > 0 && (
                    <svg>

                      <mask id="myMask">
                        <path
                          id="pieBig"
                          fill="white"
                          d={`M${this.centerX},${this.centerY} 
                            L${this.centerX - this.centerX},${this.centerY}  
                            A${this.radius},${this.radius} 1 1,0 ${this.centerX + (this.radius * Math.cos(Math.PI))},${this.centerY + (this.radius * Math.sin(Math.PI))} z`}
                        />

                      </mask>
                      <image
                        className="score-image" 
                        href="/assets/logo-shape-beach.png"
                        mask="url(#myMask)"
                      />

                      <animate
                        href="#pieBig"
                        xlinkHref="#pieBig"
                        attributeName="d"
                        dur="1s"
                        from={`M${this.centerX},${this.centerY} 
                            L${this.centerX - this.centerX},${this.centerY}  
                            A${this.radius},${this.radius} 1 1,0 ${this.centerX + (this.radius * Math.cos(Math.PI))},${this.centerY + (this.radius * Math.sin(Math.PI))} z`}
                        to={`M${this.centerX},${this.centerY} 
                            L${this.centerX - this.centerX},${this.centerY}  
                            A${this.radius},${this.radius} 1 1,0 ${this.centerX + (this.radius * Math.cos(this.angle))},${this.centerY + (this.radius * Math.sin(this.angle))} z`}
                        fill="freeze"
                      />

                    </svg>
                  )}
                </div>

              </div>
              <div className="labelling">
                <div>{name}</div>
                <div>{owner}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default withRouter(ScoreCardSummaryBar);