/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Input from '../../uiToolkit/components/input';
import Page from '../../uiToolkit/containers/page';
import Button from '../../uiToolkit/components/button';
import { ApplicationState } from '../../store';
import * as ScoreWeightingStore from '../../store/configuration/ScoreWeightings';
import Message from '../Common/Message/message';
import ReactGA from 'react-ga';
import Loading from '../Common/Loading/loading';
import { toast } from 'react-toastify';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  scoreWeightings: state.scoreWeightings,
});
const mapDispatch = {
  ...ScoreWeightingStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

interface IState {
    id: number,
    currentDataWeight: number, 
    recentDataWeight: number,
    comparativeScoreWeight: number,
    progressiveScoreWeight: number,
    formIsSubmitted: boolean
}

class ScoreWeightings extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      id: 0,
      currentDataWeight: 0,
      recentDataWeight: 0, 
      comparativeScoreWeight: 0,
      progressiveScoreWeight: 0,
      formIsSubmitted: false,
    };
  }

  public componentDidMount() {
    const { requestScoreWeightings } = this.props;
    
    ReactGA.pageview(window.location.pathname + window.location.search);

    requestScoreWeightings();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { scoreWeightings: {
      scoreWeighting: {
        id, 
        currentDataWeight, 
        recentDataWeight, 
        comparativeScoreWeight, 
        progressiveScoreWeight, 
      }, 
      isLoading, isUpdated, isErrored } } = this.props;

    if (!isLoading && this.state.currentDataWeight == 0 && this.state.recentDataWeight == 0 && 
      this.state.comparativeScoreWeight == 0 && this.state.progressiveScoreWeight == 0) {
      this.setState({
        id,
        currentDataWeight, 
        recentDataWeight, 
        comparativeScoreWeight, 
        progressiveScoreWeight,
      });
    } else {
      this.validateInput();
    }

    if(isUpdated && !prevProps.scoreWeightings.isUpdated) {
      toast.error('Successfully updated score weightings');
    } else if(isErrored && !prevProps.scoreWeightings.isErrored) {
      toast.error('Failed saving score weightings', {
        className: 'toast-popup error',
      });
    }
  }

  public validateInput() {
    const { currentDataWeight, recentDataWeight, comparativeScoreWeight, progressiveScoreWeight } = this.state;
    this.setState({
      formIsSubmitted: (currentDataWeight + recentDataWeight !== 100) || (comparativeScoreWeight + progressiveScoreWeight !== 100)
    });
  }

  public handleSave() {
    const { saveScoreWeightings } = this.props;
    const { id, currentDataWeight, recentDataWeight, comparativeScoreWeight, progressiveScoreWeight } = this.state;

    saveScoreWeightings({ 
      id, 
      currentDataWeight: currentDataWeight/100, 
      recentDataWeight: recentDataWeight/100, 
      comparativeScoreWeight: comparativeScoreWeight/100, 
      progressiveScoreWeight: progressiveScoreWeight/100, 
    });
  }

  public render() {
    const { scoreWeightings } = this.props;
    const { currentDataWeight, recentDataWeight, comparativeScoreWeight, progressiveScoreWeight, formIsSubmitted } = this.state;

    return (
      <Page mode=" center"
        pageNumber={1}
        padding>
        <div className="wrapper">
          <div className="text-wrapper">
            <h1>Configure Score Weightings</h1>
            <p>
            Please note, changes to these percentage weightings will be reflected only
            when data is processed within the current or future
            reporting periods.
            </p>
          </div>

          {scoreWeightings.isLoading && <Loading/>}
          
          {scoreWeightings.isErrored && <Message text="Errored..." />}

          {!scoreWeightings.isLoading && !scoreWeightings.isErrored &&
          <>
            <div className='text-wrapper'>
              <h2>Recency data bias</h2>
            </div>
            <div className="input-wrapper">
              <div className="input">
                <p><strong>Current data weight</strong> - This percentage will apply to the data of the current week.</p>
                <Input
                  name="allTime"
                  label=""
                  inputType="number"
                  placeholderText=''
                  onChange={(e: any) => this.setState({ currentDataWeight: parseInt(e) })}
                  value={currentDataWeight.toFixed().toString()}
                  valid={formIsSubmitted ? true : false}
                  max={100}
                  min={0}
                />
              </div>

              <div className="input">
                <p><strong>Recent data weight</strong> - This percentage will apply to the data of the past 8 weeks.</p>
                <Input
                  name="recent"
                  label=""
                  inputType="number"
                  placeholderText=''
                  onChange={(e: any) => this.setState({ recentDataWeight: parseInt(e) })}
                  value={recentDataWeight.toFixed().toString()}
                  valid={formIsSubmitted ? true : false}
                  max={100}
                  min={0}
                />
              </div>
            </div>

            <div className='text-wrapper'>
              <h2>Score bias</h2>
            </div>
            <div className="input-wrapper">
              <div className="input">
                <p><strong>Comparative score weight</strong> - How much weight has the comparative score on the final score.</p>
                <Input
                  name="current"
                  label=""
                  inputType="number"
                  placeholderText = ''
                  onChange={(e: any) => this.setState({ comparativeScoreWeight: parseInt(e) })}
                  value={comparativeScoreWeight.toFixed().toString()}
                  valid={formIsSubmitted ? true : false}
                  max={100}
                  min={0}
                />
              </div>

              <div className="input">
                <p><strong>Progressive score weight</strong> - How much weight has the progressive score on the final score.</p>
                <Input
                  name="current"
                  label=""
                  inputType="number"
                  placeholderText = ''
                  onChange={(e: any) => this.setState({ progressiveScoreWeight: parseInt(e) })}
                  value={progressiveScoreWeight.toFixed().toString()}
                  valid={formIsSubmitted ? true : false}
                  max={100}
                  min={0}
                />
              </div>
            </div>
          </>    
          }

          {!scoreWeightings.isLoading && !scoreWeightings.isErrored &&
            <div className="save-btn">
              <Button
                text="Save"
                onClick={() => this.handleSave()}
                enabled={formIsSubmitted ? false : true}
              />
              <p hidden={formIsSubmitted ? false : true}
                className="error-message">The total sum of percentage values should be 100</p>
            </div>
          }
        </div>
      </Page>
    );
  }
}

export default connector(withRouter(ScoreWeightings));
