/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import ReactGA from 'react-ga';
import { MultiSelect } from 'react-multi-select-component';
import ToolTips from '../../Common/ToolTips/toolTips';

type ScoreListMetricProps = {
    label: string,
    children?: React.ReactNode,
    id: number,

    flavourOptions?: {data: string, label: string}[],
    sortOptions?: {data: string, label: string}[],

    selectedFlavourKey?:string,

    onFlavourChange?:Function,
    onSortChange?:Function,

    tooltip: string,
    grandparentClicked: boolean,
    handleGrandparentClicked: Function,

    editMode?: boolean,
    metricVariables: {data: string, label: string, value: string}[], 
    onInputChange: Function,
    onDelete: Function,
 }

export default class ScoreListMetric extends React.PureComponent<ScoreListMetricProps, {tipToggled:boolean}> {
  constructor(props: ScoreListMetricProps) {
    super(props);

    this.state = {
      tipToggled: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick(e: React.MouseEvent, value: boolean){
    e.stopPropagation();
    this.setState({ tipToggled: value });
  }

  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public componentDidUpdate(){
    if (this.props.grandparentClicked){
      this.setState({ tipToggled: false });
    }
  }
  
  public render() {
    const {
      label, children, tooltip, 
      flavourOptions, selectedFlavourKey, onFlavourChange,
      sortOptions, onSortChange, editMode,onInputChange,
      metricVariables, onDelete,id,
    } = this.props;

    const selectedSortOptions = sortOptions ? sortOptions.map((s) => ({ value: s.data, data: s.data, label: s.label })) : [];

    return (
      <div className="score-list-metric ui-toolkit">
        <div className="header">
          <div className ="header-tooltip">
            <h2>
              {
                editMode ? 
                  <div className='sub-header'>
                    <input className='metric-sequence'
                      type='text'
                      value={id} 
                      onChange={(e) => onInputChange(e.target.value, 'id')}/>
                    <input 
                      onChange={(e) => onInputChange(e.target.value, 'label')}
                      type='text'
                      placeholder='Label'
                      value={label} /> 
                  </div>
                  :
                  label}
            </h2>
            <div className ="toolTip-container">
              {editMode || tooltip !== '' ?
                <ToolTips
                  handleParentClick={this.handleClick}
                  handleGrandparentClick={this.props.handleGrandparentClicked}
                  tipToggled={editMode ? true : this.props.grandparentClicked? false : this.state.tipToggled}
                  text = {tooltip} 
                  editMode={editMode}
                  onInputChange={(value: string, fieldName: string) => onInputChange(value, fieldName)}
                /> : null}
            </div>
          </div>
          <div className="options">
            {flavourOptions && flavourOptions.length > 0 && onFlavourChange
              ? (
                <select className='flavour'
                  value={selectedFlavourKey}
                  onChange={(e) => onFlavourChange(e.target.value)}>
                  <option value="">All</option>
                  {flavourOptions.map((f:any) => 
                    <option key={f.data}
                      value={f.data}>{f.label}</option>)}
                </select>
              )
              : null}
            {editMode ?
              metricVariables &&
              <MultiSelect
                className='sort-options'
                options={metricVariables.filter((m) => m.label.length > 0)}
                onChange={(e: any) => onInputChange(e, 'sort')} 
                value={selectedSortOptions}
                labelledBy="Select"
              />
              : 
              sortOptions && sortOptions.length > 0 && onSortChange
                ? (
                  <select onChange={(e) => onSortChange(e.target.value)}>
                    {sortOptions.map((f:any) => <>
                      <option 
                        key={`${f.data}_desc`}
                        value={`${f.data}_desc`}>
                        {`${f.label} Desc`}
                      </option>
                      <option 
                        key={`${f.data}_asc`}
                        value={`${f.data}_asc`}>
                        {`${f.label} Asc`}
                      </option>
                    </>)}
                  </select>
                )
                : null}
          </div>
        </div>
        { 
          editMode &&
            <div className='delete'>
              <img
                onClick={() => onDelete()}
                src='/assets/cross.png'/>
            </div>
        }
        <div>
          {children}
        </div>
      </div>
    );
  }
}
