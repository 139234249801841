import * as React from 'react';
import ReactGA from 'react-ga';

type ScoreListItemMetricProps = 
  { 
    label: string,
    style: string, 
    children?: React.ReactNode, 
    editMode?: boolean
    addMetricHandler: Function,
    metricVariables?: string[],
    onInputChange: Function,
  } 

export default class ScoreListItemMetric extends React.PureComponent<ScoreListItemMetricProps, {}> {
  public componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  public render() {
    const { style, label, children, editMode, addMetricHandler, onInputChange, metricVariables } = this.props;

    return (
      <div className="score-list-item-metric ui-toolkit">
        <div className={`header ${style}`}>
          {
            editMode ? 
              <select className='variable-selector'
                value={label} 
                onChange={(e) => onInputChange(e.target.value)}>
                <option value={''}>Numbered</option>
                {
                  metricVariables && metricVariables.map(m => (
                    <option key={m}
                      value={m}>{m}</option>
                  ))
                }

              </select>
              : 
              label
          } 
        </div>
        
        <div className="metrics">
          {children}
          {
            editMode && 
            <div className='add-metric'>
              <button onClick={() => addMetricHandler()}
                className='add-metric-btn'>Add new metric +
              </button>
            </div>
          }
        </div>
        <datalist id="styleOptions">
          {
            metricVariables && metricVariables.map(m => (
              <option key={m}>{m}</option>
            ))
          }
          
        </datalist>
      </div>
    );
  }
}
