/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as qs from 'qs';
import Page from '../../uiToolkit/containers/page';
import ScoreValueMetric from './ScoreValueMetric/scoreValueMetric';
import ScoreTextMetric from './ScoreTextMetric/scoreTextMetric';
import ScoreListItemMetric from './ScoreListItemMetric/scoreListItemMetric';
import ScoreListItemValueMetric from './ScoreListItemValueMetric/scoreListItemValueMetric';
import ScoreListMetric from './ScoreListMetric/scoreListMetric';
import ScoreCardSummaryBar from './ScoreCardSummaryBar/scoreCardSummaryBar';
import { ApplicationState } from '../../store';
import * as ScoreAreaTypes from '../../store/area/types';
import * as ScoreAreaActions from '../../store/area/actions';
import * as ScoreActions from '../../store/score/actions';
import * as AuthActions from '../../store/authentication/actions';
import Message from '../Common/Message/message';
import CronDateDropdown from '../Common/CronDateDropdown/cronDateDropdown';
import ReactGA from 'react-ga';
import ScoreTrendChart from '../Common/TrendChart/ScoreTrendChart';
import { getLatestValidDate } from '../../uiToolkit/helpers/cronHelper';
import Loading from '../Common/Loading/loading';
import { AuthManager } from '../../AuthManager';
import MetricChart from './MetricTrendChart/metricChart';
import { TrendFilter } from '../../enums/TrendFilter';
import { MetricTrend } from '../../store/area/types';
import { WaveScore } from '../Common/WaveScore/waveScore';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  score: state.score,
  scoreArea: state.scoreArea,
});
const mapDispatch = {
  ...ScoreAreaActions.actionCreators,
  ...AuthActions.actionCreators,
  ...ScoreActions.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { scoreid?: string, areaid: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type Props =
  PropsFromRedux
  & PropsType;

interface IState {
  areaId: string;
  reportingEntity: string;
  dateKey: string;
  flavourKey?:string;
  sortKey?:string;
  sortAsc:boolean;
  grandparentClicked: boolean;
  showGraphs: { [id: string]: boolean };
}

class ScoreArea extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      areaId: '',
      reportingEntity: (props.authentication
        && props.authentication.selectedReportingEntity) || '',
      dateKey: (props.authentication && props.authentication.selectedDateKey) || '',
      flavourKey: undefined,
      sortKey: undefined,
      sortAsc: true,
      grandparentClicked: false,
      showGraphs: {},
    };
    
    this.handleGrandparentClicked = this.handleGrandparentClicked.bind(this);
  }
  
handleToggleGraph = (id: string) => {
  this.setState((prevState) => {
    const updatedShowGraphs = { ...prevState.showGraphs };
    updatedShowGraphs[id] = !prevState.showGraphs[id];
    return { showGraphs: updatedShowGraphs };
  });
};

public componentDidMount() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const {
    history, match, setCurrentDate, setCurrentReportingEntity,
    authentication, score, requestScore,
  } = this.props;

  const { dateKey, reportingEntity, areaId } = this.state;

  const query = qs.parse(history.location.search.replace('?', '').toLowerCase());
  const tenantDetails = authentication && authentication.tenantDetails;

  let updateState = false;
  let newState = {};

  const queryDateKey: string = (query.datekey && query.datekey.toString()) || '';
  const stateDateKey: string = dateKey;

  // Manage the date state
  if (stateDateKey.length === 0 && queryDateKey.length > 0) {
    // set state correctly
    setCurrentDate(queryDateKey);
    newState = ({ ...newState, dateKey: queryDateKey });
    updateState = true;
  } else if (queryDateKey.length === 0) {
    const latestValidDate = getLatestValidDate(tenantDetails && tenantDetails.reportingWindow);
    setCurrentDate(latestValidDate);
    newState = ({ ...newState, dateKey: latestValidDate });
    updateState = true;
  }

  const stateEntityKey = reportingEntity.toLowerCase();
  const queryEntityKey = (match.params.scoreid || '').toLowerCase();

  // Manage the site data
  if (stateEntityKey.length === 0 && queryEntityKey.length > 0) {
    // set state correctly
    setCurrentReportingEntity(queryEntityKey);
    newState = ({ ...newState, reportingEntity: queryEntityKey });
    updateState = true;
  }

  const stateAreaKey = areaId.toLowerCase();
  const queryAreaKey = (match.params.areaid || '').toLowerCase();

  // Manage the area data
  if (stateAreaKey.length === 0 && queryAreaKey.length > 0) {
    // set state correctly
    newState = ({ ...newState, areaId: queryAreaKey });
    updateState = true;
  }

  if (updateState) {
    this.setState(newState);
  }

  const authManager = new AuthManager();

  if ((score === undefined || score.siteId === undefined) && (score && !score.isLoading)) {
    if(authManager.IsUserAuthenticated()) {
      requestScore(
        stateDateKey.length === 0 ? queryDateKey : stateDateKey,
        stateEntityKey.length === 0 ? queryEntityKey : stateEntityKey,
      );   
    }
  }
}

public componentDidUpdate(prevProps: Props, prevState: IState) {
  const {
    history, match, requestScoreArea, requestAreaMetricTrend,
  } = this.props;
  const { dateKey, reportingEntity, areaId } = this.state;

  if (match.params.areaid !== prevProps.match.params.areaid) {
    this.setState({ areaId: match.params.areaid });
  }

  // current date key changed
  if (dateKey !== prevState.dateKey
      || reportingEntity !== prevState.reportingEntity
      || areaId !== prevState.areaId) {
    if (dateKey.length > 0 && reportingEntity.length > 0 && areaId.length > 0) {
      // update query string
      history.replace(`/score/${reportingEntity}/area/${areaId}/?datekey=${dateKey}`);

      const authManager = new AuthManager();

      if(authManager.IsUserAuthenticated()) {
        requestScoreArea(dateKey, areaId, reportingEntity);
        requestAreaMetricTrend(reportingEntity, areaId, dateKey, TrendFilter.ONE_YEAR);
      }
    }
  }
}

public dateChanged(dateKey: string) {
  const { setCurrentDate } = this.props;

  setCurrentDate(dateKey);
  this.setState({ dateKey });
}

public renderMetrics() {
  const { scoreArea } = this.props;

  const components = scoreArea && scoreArea.template && scoreArea.template.components;
  const sequence = components && components.sequence;

  if (sequence === undefined) {
    return null;
  }

  const metrics = scoreArea && scoreArea.metrics;
  const previousMetrics = (scoreArea && scoreArea.prevMetrics) || {};

  if (metrics === undefined) {
    return null;
  }

  const renderedComponents: (JSX.Element | null)[] = [];

  for (let i = 0; i < sequence.length; i += 1) {
    const currentSequence = sequence[i];

    const textComponent = components && components.textMetrics
      && components.textMetrics.find((t) => t.id === currentSequence);

    if (textComponent) {
      renderedComponents.push(this.renderTextMetric(textComponent, metrics));
      continue;
    } 
        
    const valueComponent = components && components.valueMetrics
        && components.valueMetrics.find((t) => t.id === currentSequence);

    if (valueComponent) {
      renderedComponents.push(this.renderValueMetric(valueComponent, metrics, previousMetrics));
      continue;
    } 
        
    const listComponent = components && components.listMetrics
        && components.listMetrics.find((t) => t.id === currentSequence);

    if (listComponent) {

      const listMetrics = scoreArea && scoreArea.listMetrics && scoreArea.listMetrics[listComponent.data];
      if(listMetrics){
        const itemLookupData = (scoreArea && scoreArea.itemDataLookup) || {};
        renderedComponents.push(this.renderListMetric(listComponent, listMetrics, itemLookupData ));
      }
    }
  }

  return renderedComponents;
}

public replacePlaceholders(text:string, values: {[id:string]: any}, extraValues ?: ScoreAreaTypes.ItemData): string{

  if(typeof(text) !== 'string'){
    return text;
  }

  const placeholders = text.match(/\{\{[a-z0-9]+\}\}/ig);
  const replaceValues = (placeholders && placeholders.map((match) => match.replace('{{', '').replace('}}', ''))) || [];

  for (let i = 0; i < replaceValues.length; i += 1) {
    let value = values[replaceValues[i]];
      
    if(replaceValues[i] === 'description' && extraValues && extraValues.description){
      value = extraValues.description;
    }

    if (value !== undefined) {
      text = text.replace(`{{${replaceValues[i]}}}`, value);
    }else{
      text = text.replace(`{{${replaceValues[i]}}}`, '');
    }
  }

  return text;
}

  

public renderTextMetric(component: ScoreAreaTypes.TextMetric, metrics: ScoreAreaTypes.Metrics) {
  const { tooltip } = component;
    
  let { text } = component;

  text = this.replacePlaceholders(text, metrics, undefined);
    
  return (
    <ScoreTextMetric key={text}
      id={component.id}
      text={text} 
      grandparentClicked={this.state.grandparentClicked}
      handleGrandparentClicked={this.handleGrandparentClicked}
      tooltip = {tooltip}
      editMode={false}
      onDelete={() => null}
      onInputChange={() => null}
    />);
}

public renderValueMetric(component: ScoreAreaTypes.ValueMetric, metrics: ScoreAreaTypes.Metrics, previousMetrics: ScoreAreaTypes.Metrics) {
  const { value, total } = component; 
  //let { change } = component; 
  const { increaseGood, format, tooltip } = component;
  const { scoreArea } = this.props;
  const { dateKey } = this.state;
  
  let prevValue = '';
  let newValue = '';
  let newTotal = '';
  let change = 0;

  newValue = this.replacePlaceholders(value, metrics); 
  prevValue = this.replacePlaceholders(value, previousMetrics);
  newTotal = this.replacePlaceholders(total, metrics);

  if ((prevValue && Number.parseFloat(prevValue)) || (newValue && Number.parseFloat(newValue))) {
    const previous = Number.parseFloat(prevValue) || 0;
    const current = Number.parseFloat(newValue) || 0;
    change = (current - previous);
  }

  if(newValue){
    return (
      <> 
        <ScoreValueMetric
          id={component.id}
          key={component.label}
          label={component.label}
          value={newValue}
          total={newTotal}
          format={format}
          change={change}
          increaseGood={increaseGood}
          tooltip = {tooltip}
          grandparentClicked={this.state.grandparentClicked}
          handleGrandparentClicked={this.handleGrandparentClicked}
          onInputChange={() => null}
          onDelete={() => null}
          showGraph={this.state.showGraphs[component.id] || false}
          onToggleGraph={() => this.handleToggleGraph(component.id.toString())}
        />

        { 
       
          this.state.showGraphs[component.id] && 
           <MetricChart 
             onClose={() => this.handleToggleGraph(component.id.toString())}
             metricData={this.getMetricTrendByName(scoreArea.areaMetricTrends, component.value.replace(/{{|}}/g, ''))}
             reportingStartDate={dateKey}

           />
        }
      </>
    );
  }

  return null;
}

public getMetricTrendByName(trendData: MetricTrend[], metricName: string): { reportingWindowStart: string; value: number }[] {
  return trendData.map((item) => {
    const targetMetric = item.metrics.find((metric) => metric.label.toLowerCase() === metricName.toLowerCase());
    if (targetMetric) {
      return {
        reportingWindowStart: item.reportingWindowStart,
        value: targetMetric.value,
      };
    } 
    return null; 
  }).filter(Boolean) as { reportingWindowStart: string; value: number }[]; // cast filter result to the correct return type
}

public renderListMetric(component: ScoreAreaTypes.ListMetric, metrics: ScoreAreaTypes.Metrics[], itemDataLookup: {[id: string]: ScoreAreaTypes.ItemData}) {
  const { flavourKey, sortKey, sortAsc } = this.state;

  const item = component.items;
  const lookUpKey  = component.flavouredData || '';
  const metricData = metrics;
  const { tooltip } = component;

  if (!metricData || !item) {
    return null;
  }

  //Sort data
  let sortedData = metricData;

  if(sortKey){
    if(sortAsc){
      sortedData = metricData.sort((a,b) => a[sortKey] - b[sortKey]);
    }else{
      sortedData = metricData.sort((a,b) => b[sortKey] - a[sortKey]);
    }
  }else{
    if(component.sortOptions && component.sortOptions[0]){
      this.setState({ sortKey: component.sortOptions[0].data, sortAsc: false });
    }
  }
    

  const components: JSX.Element[] = [];
  let flavourItems: {data:string, label:string}[] = [];

  for (let i = 0; i < sortedData.length; i += 1) {

    let itemData:ScoreAreaTypes.ItemData | undefined = undefined;

    //Get Matching Item
    const lookUpValue = sortedData[i][lookUpKey];

    if(lookUpValue){
      itemData = itemDataLookup[lookUpValue];
    }

    //Get Flavoured Options
    if(itemData){
      flavourItems.push({ data: itemData.categoryId.toString(), label: itemData.categoryName });
    }
      
    //Filter Data
    if((!flavourKey || !itemData || flavourKey === itemData.categoryId.toString()) && components.length < 10){
      components.push(this.renderListItemMetric(item, sortedData[i], component.style, i, itemData));
    }
  }

  flavourItems = flavourItems.filter((value, index, self) => self.findIndex((m) => m.data === value.data ) === index);

  return (
    <ScoreListMetric
      key={component.label}
      id={component.id}
      label={component.label}
      tooltip = {tooltip}
      grandparentClicked={this.state.grandparentClicked}
      handleGrandparentClicked={this.handleGrandparentClicked}
      flavourOptions={flavourItems}
      sortOptions={component.sortOptions}
      selectedFlavourKey={flavourKey}
      onFlavourChange={ (key:string) => this.setState({ flavourKey: key })}
      onSortChange={(key:string) => this.setState({ sortKey: key.replace('_asc', '').replace('_desc', ''), sortAsc: key.indexOf('_asc') > -1 })}
      onInputChange={() => null}
      metricVariables={[]}
      onDelete={() => null}
    >
      {components && components.length > 0 ? components : <Message text="No Items Founds"/>}
    </ScoreListMetric>
  );
}

public renderListItemMetric(component: ScoreAreaTypes.ListItemRow,
  metrics: ScoreAreaTypes.Metrics, style: string, index: number, itemData?: ScoreAreaTypes.ItemData) {
  const components: JSX.Element[] = [];

  let { title } = component;
    
  title = this.replacePlaceholders(title, metrics, itemData); 

  if(style === 'numbered'){
    title = (index+1).toString();
  }

  const key =((itemData && itemData.label) || title) + index; 

  for (let i = 0; i < component.metrics.length; i += 1) {
    components.push(this.renderListItemValueMetric(component.metrics[i], metrics, key, itemData));
  }

  return (
    <ScoreListItemMetric key={key}
      label={title}
      style={style}
      addMetricHandler={() => null}
      onInputChange={() => null}>
      {components}
    </ScoreListItemMetric>
  );
}

public renderListItemValueMetric(component: ScoreAreaTypes.ListItemMetric,
  metrics: ScoreAreaTypes.Metrics, parentKey:string,  itemData?: ScoreAreaTypes.ItemData) {
    
  const { format } = component;
    
  const value = this.replacePlaceholders(component.value, metrics, itemData);
  const label = this.replacePlaceholders(component.label, metrics, itemData); 
  const suffix = component.suffix ? this.replacePlaceholders(component.suffix, metrics, itemData): ''; 

  return (<ScoreListItemValueMetric key={parentKey + '-' + label}
    label={label}
    value={value}
    format={format}
    suffix={suffix}
    onInputChange={() => null}
    onDelete={() => null} />);
}

public handleGrandparentClicked(e: React.MouseEvent, value: boolean){
  e.stopPropagation();
  this.setState({ grandparentClicked: value });
}

public render() {
  const { authentication, score, scoreArea } = this.props;
  const { dateKey } = this.state;

  const userDetails = authentication;
  const tenantDetails = userDetails.tenantDetails;
  const scoreDetails = score;
  const scoreAreaDetails = scoreArea;
  const metrics = this.renderMetrics() || [];

  const scoringEntity = userDetails 
      && scoreAreaDetails 
      && userDetails.scoringEntityLookup 
      && userDetails.scoringEntityLookup[scoreAreaDetails.siteId];

  const scoringArea = userDetails 
      && scoreAreaDetails 
      && userDetails.scoreAreaLookup 
      && userDetails.scoreAreaLookup[scoreAreaDetails.area];

  const areaTrend = (scoreAreaDetails && scoreAreaDetails.trend && scoreAreaDetails.trend.scoreTrends) || [];
  const prevAreaTrend = (scoreAreaDetails && scoreAreaDetails.prevTrend && scoreAreaDetails.prevTrend.scoreTrends) || [];

  const threshold = (tenantDetails && tenantDetails.threshold) || 0;

  let scoreChange = '-';

  const currentScore = scoreAreaDetails.rawScore;
  const prevScore = scoreAreaDetails.prevRawScore;

  scoreChange = (currentScore - prevScore).toFixed(1);

  let scoreDirection = '';

  if (scoreChange.indexOf('-') === -1) {
    scoreDirection = 'up';
    scoreChange = `+${scoreChange}`;
  } else if (scoreChange.length > 1 && scoreChange.indexOf('-') > -1) {
    scoreDirection = 'down';
  }

  return (
    <div
      onClick={(e) => this.handleGrandparentClicked(e, true)}>
      <Page pageNumber={1}
        mode="none score-area">
        <ScoreCardSummaryBar
          score={(scoreDetails && scoreDetails.rawScore) || 0}
          maxScore={5}
          companyImageLink="/assets/logo_starbucks.svg"
          name={(scoringEntity && scoringEntity.name) || ''}
          owner={(scoringEntity && scoringEntity.owner.name) || ''}
        />
        <div className="area-header">
          <div className="wrapper">
            <div className="cards">
              <div className="left-cards">
                <div className="title">{(scoringArea && scoringArea.name) || 'Score Area'}</div>
                <div className="area-score-card">
                  <WaveScore
                    score={(scoreAreaDetails && scoreAreaDetails.rawScore) || 0}
                    maxScore={5}
                    index={2}
                  />
                  <div className={`change ${scoreDirection}`}>{scoreChange}</div>
                </div>

              </div>
              <div className="trend-card">
                <div className="trend-chart">
                  <ScoreTrendChart 
                    scoreTrend = {areaTrend.concat(prevAreaTrend)}
                    threshold = {threshold}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="metrics-outer">
          <div className="metric-container">
            <div className="metric-header">
              <div className="large-header">Statistics</div>
              <CronDateDropdown
                cronString={(tenantDetails && tenantDetails.reportingWindow) || ''}
                currentDateKey={dateKey}
                onChange={(dk: string) => { this.dateChanged(dk); }}
                placeholderText="Select date range"
                isGrey
              />
            </div>
              
            <div className='loader-style'>
              <div>{scoreArea && scoreArea.isLoading && <Loading/>}
              </div>
            </div>

            {metrics.length === 0 && !scoreArea.isLoading ? <Message text="No data currently available." /> : metrics}
          </div>
        </div>
      </Page>
    </div>
  );
}
}

export default connector(withRouter(ScoreArea));
