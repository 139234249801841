import * as React from 'react';
import ReactGA from 'react-ga';
import { Avatar } from '../../Common/Avatar/avatar';

interface props {
    companyImageLink: string,
    backgroundImageLink: string,
    scoreId: string,
    scoreName: string,
    companyName: string,
    ownerName: string,
}

export default class UserSummary extends React.PureComponent<props, {}> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  public render() {
    const {
      scoreName,
      companyName,
      ownerName,
      companyImageLink,
      backgroundImageLink,
      scoreId,
    } = this.props;

    return (
      <div className="user-summary ui-toolkit"
        style={{ backgroundImage: `url(${backgroundImageLink})` }}>
        <Avatar companyImageLink={companyImageLink}
          label={scoreId} />
        <div className="user-details">
          <div className="name">{scoreName.replace(scoreId, '').trim()}</div>
          <div className="tenant">{companyName}</div>
          <div className="owner">{ownerName}</div>
        </div>
      </div>
    );
  }
}
