import { toast } from 'react-toastify';
import { Action, Reducer } from 'redux';
import { AuthManager } from '../../AuthManager';
import { TenantDetails,UserDetails, ScoreArea, Banding, ScoringEntity, AuthenticationAction } from './types';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthenticationState {
    //status
    isLoading: boolean;
    isErrored: boolean;
    isAuthenticated: boolean;
    errorMessage: string;
    //data
    permissions: number[];
    tenantDetails?: TenantDetails;
    userDetails?: UserDetails;
    scoreAreas?: ScoreArea[];
    bandings?: Banding[];
    scoringEntities?: ScoringEntity[];
    //Lookups
    bandingLookup?: {[id:string]: Banding};
    scoreAreaLookup?: {[id:string]: ScoreArea};
    scoringEntityLookup?: {[id:string]: ScoringEntity};
    //Navigation Properties
    selectedReportingEntity?: string;
    selectedDateKey?: string;
}

// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: AuthenticationState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isAuthenticated: false,
  permissions: [],
  scoreAreas: [],
  bandings: [],
  scoringEntities: [],
  //Lookups
  bandingLookup:{},
  scoreAreaLookup:{},
  scoringEntityLookup:{},
};

export const reducer: Reducer<AuthenticationState> = (state: AuthenticationState | undefined,
  incomingAction: Action): AuthenticationState => {
  if (state === undefined) {
    return unloadedState;
  }

  const authManager = new AuthManager();

  const action = incomingAction as AuthenticationAction;
  switch (action.type) {
  case 'REQUEST_AUTHENTICATION':
    localStorage.removeItem('token');
    localStorage.removeItem('expiry');
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };

  case 'RECEIVE_AUTHENTICATION':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isAuthenticated: true,
      userDetails: action.payload.userDetails,
      tenantDetails: action.payload.tenantDetails,
      scoreAreas: action.payload.scoreAreas,
      permissions: action.payload.permissions,
      bandings:action.payload.bandings || [],
      scoringEntities:action.payload.scoringEntities,
      bandingLookup: action.payload.bandings && Object.fromEntries(action.payload.bandings.map(b => [b.id, b])),
      scoreAreaLookup: action.payload.scoreAreas && Object.fromEntries(action.payload.scoreAreas.map(b => [b.dataId, b])),
      scoringEntityLookup: action.payload.scoringEntities && Object.fromEntries(action.payload.scoringEntities.map(b => [b.dataId, b])),
    };
  case 'FAILED_AUTHENTICATION':
    toast.error('An error occurred while attempting to sign in', {
      className: 'toast-popup error',
    });
    authManager.logout();
    return {
      ...unloadedState,
      errorMessage: action.payload ? action.payload : 'An error occurred.',
      isErrored: true,
      isLoading: false,
    };

  case 'SET_CURRENTENTITY':
    return {
      ...state,
      selectedReportingEntity: action.reportingEntityId,
    };

  case 'SET_CURRENTDATE':
    return {
      ...state,
      selectedDateKey: action.dateKey,
    };
  default:
    return state;
  }
};
