import * as React from 'react';
import ProgressBar from '../../Common/ProgressBar/progressBar';
import ReactGA from 'react-ga';

interface propTypes {
    index: number,
    title: string,
    score: number,
    maxScore: number,
    onStreak?: boolean,
    detailClick: Function,
    backgroundImagePath: string,
}

export class AreaSummary extends React.PureComponent<propTypes, {}> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  public render() {
    const {
      maxScore, score, backgroundImagePath, detailClick, index,
      title,
    } = this.props;

    return (
      <div className="area-summary ui-toolkit">
        { this.props.onStreak && <img className='flame-icon'
          src='/assets/flame.png'/> }
        <img src={`/assets/areas/${backgroundImagePath}`}
          alt={title} />
        <div className="img-title">
          {title}
        </div>
     
        <ProgressBar 
          score={score}
          maxScore={maxScore}
          index={index} />
    
        <div className="score">
          {`${score.toFixed(1)}/${maxScore}`}
        </div>
        <button 
          type="button"
          onClick={() => detailClick()}
        >
          <div className="chevron" />
        </button>
      </div>
    );
  }
}

export default AreaSummary;
