import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import { ApplicationState } from '../../store';
import * as TenantsStore from '../../store/dashboard/Tenants';
import * as ReactGA from 'react-ga';
import Table from '../../uiToolkit/containers/table';
import Error from '../Common/Error/error';
import Loading from '../Common/Loading/loading';
import { AuthManager } from '../../AuthManager';


const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  tenants: state.tenants,
});
const mapDispatch = {
  ...TenantsStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

class Tenants extends React.PureComponent<IProps, {}> {

  public componentDidMount() {
    const { requestTenants } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    requestTenants();
  }

  public componentDidUpdate(prevProps: IProps){
    const { tenants } = this.props;

    if(tenants.isUpdated && !prevProps.tenants.isUpdated && !tenants.isLoading && !tenants.isErrored){
      const authManager = new AuthManager();
      authManager.logout();
    }
  }

  switchTenant(tenantDataId: string) {
    const { switchTenant } = this.props;

    switchTenant(tenantDataId);
  }

  public render() {
    const { history, tenants, authentication } = this.props;

    return (
      <div className="dashboard ui-toolkit">
        <Page mode="center dashboard"
          pageNumber={1}
          padding>
          <div className="dashboard-wrapper">
            <div className="dashboard-content">
              <Page mode="center"
                pageNumber={1}>
                <Table
                  add
                  addClick={() => this.props.history.push('/dashboard/editTenant/0')}
                  addLabel="Add New Tenant"
                  search={false}
                  searchClick={() => null}
                  initialValue=""
                  pagination={false}
                  pageSize={1}
                  pageNumber={1}
                  pageChange={() => null}
                  totalRecords={1}
                >
                  <thead>
                    <tr>
                      <th className="padding title">Switch</th>
                      <th className="padding title"></th>
                      <th className="padding title">Name</th>
                      <th className="padding title">Threshold</th>
                      <th className="padding title">Edit</th>
                      <th className="padding title">Edit Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenants.isLoading
                      && <tr><td colSpan={6}><Loading/></td></tr>}

                    {tenants.isErrored
                      && <tr><td colSpan={6}><Error text={tenants.errorMessage} /></td></tr>}

                    {tenants.tenants.map((t) => (
                      <tr
                        key={t.id.toString()}
                      >
                        <td className="padding">
                          {
                            (authentication.tenantDetails && authentication.tenantDetails.tenantId !== t.id) && 
                            <button onClick={() => this.switchTenant(t.dataId)}>
                              <img src='/assets/dashboard-icons/swap-icon.png'/>
                            </button>
                          }
                        </td>
                        <td className="padding"
                          onClick={() => history.push(`/dashboard/editTenant/${t.dataId}`)}><img src={`./assets/tenants/${t.tenantLogo}`} /></td>
                        <td className="padding"
                          onClick={() => history.push(`/dashboard/editTenant/${t.dataId}`)}>{t.name}</td>
                        <td className="padding"
                          onClick={() => history.push(`/dashboard/editTenant/${t.dataId}`)}>{t.threshold}</td>
                        <td className="padding"><button onClick={() => history.push(`/dashboard/editTenant/${t.dataId}`)} ><img className='edit'
                          src='/assets/edit-icon.svg' /></button></td>
                        <td className="padding">
                          <button className='edit-data-btn'
                            onClick={() => history.push(`/dashboard/tenant/${t.id}/scoringEntities`)}>Entities</button>
                          <button className='edit-data-btn'
                            onClick={() => history.push(`/dashboard/tenant/${t.id}/scoringAreas`)}>Areas</button>
                          <button className='edit-data-btn'
                            onClick={() => history.push(`/dashboard/tenant/${t.id}/scoringItems`)}>Items</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Page>
            </div>
          </div>
        </Page>
      </div>
    );
  }
}

export default connector(withRouter(Tenants));
