import { AnyAction } from 'redux';

export enum Permissions {
    EditItemCategories = 0,
    EditItemCategorisation = 10,
    EditStoreBandings = 20,
    EditUserPermissions = 30,
    EditStoreAccess = 40,
    EditAreaAccess = 50,
    EditUserDetails = 60,
    EditScoreWeightings = 70,
    EditCompanies = 80,
    EditTenants = 90,
    ViewAdminDashboard = 100,
    CreateManualSuggestions = 110,
    ViewSuggestionsHistory = 120,
}

export interface TenantDetails {
    tenantId: number,
    name: string,
    reportingWindow: string,
    logo: string,
    threshold: number
}

export interface UserDetails {
    name: string,
    job: string
}

export interface ScoreArea {
    dataId: string,
    name: string,
    image: string,
    splashImage: string,
    sequence: number
}

export interface Banding {
    id: number,
    name: string,
    hiddenText: string,
}

export interface ScoringEntity {
    dataId: string,
    name: string,
    type: string,
    bandingId: number,
    owner: Owner,
}

export interface Owner {
    name: string,
    phoneNumber: string,
    emailAddress: string
}

export interface AuthResponse {
    errorMessage: string,
    permissions: number[],
    tenantDetails?: TenantDetails,
    userDetails?: UserDetails,
    scoreAreas?: ScoreArea[],
    bandings?: Banding[],
    scoringEntities?: ScoringEntity[],
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions
// of state transitions.
// They do not themselves have any side-effects; they just describe
// something that is going to happen.

export interface RequestAuthenticationAction extends AnyAction {
    type: 'REQUEST_AUTHENTICATION';
}

export interface ReceiveAuthenticationAction extends AnyAction {
    type: 'RECEIVE_AUTHENTICATION';
    payload: AuthResponse;
}

export interface FailedAuthenticationAction extends AnyAction {
    type: 'FAILED_AUTHENTICATION';
    payload: string;
}

export interface SetCurrentEntity extends AnyAction {
    type: 'SET_CURRENTENTITY';
    reportingEntityId: string;
}

export interface SetCurrentDate extends AnyAction {
    type: 'SET_CURRENTDATE';
    dateKey: string;
}

// Declare a 'discriminated union' type. This guarantees that all
// references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AuthenticationAction =
    RequestAuthenticationAction |
    ReceiveAuthenticationAction |
    FailedAuthenticationAction |
    SetCurrentEntity |
    SetCurrentDate;