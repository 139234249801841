import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Form from '../../uiToolkit/containers/form';
import Input from '../../uiToolkit/components/input';
import Button from '../../uiToolkit/components/button';
import TextArea from '../../uiToolkit/components/textArea';
import { ApplicationState } from '../../store';
import ReactGA from 'react-ga';
import Modal from '../../uiToolkit/components/modal';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthManager } from '../../AuthManager';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
});

const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);
interface IState {
  name: string;
  email: string;
  emailError: boolean;
  message: string;
  default: number;
  complete: boolean;
  formIsSubmitted: boolean;
}
interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type IProps = PropsType;
class Contact extends React.PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      name: '',
      email: '',
      emailError: false,
      message: '',
      default: 0,
      complete: false,
      formIsSubmitted: false,
    };
  }

  public handleTextInputUpdate(value: React.ChangeEvent<HTMLInputElement> | string, fieldName: string) {
    const update: object = { [fieldName]: value };
    this.setState(update);
  }

  public componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public validateEmail(email: string) {
    const pattern = /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,20}[.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if (result) {
      this.setState({
        email: email,
        emailError: false,
      });
    } else {
      this.setState({
        emailError: true,
      });
    }
    return this.state.emailError;
  }

  public async sendEmail() {
    this.setState({ formIsSubmitted: true });

    const authManager = new AuthManager();

    if (this.validFields()) {
      authManager.generateAccessToken(() => {
        const token = authManager.getAccessToken();
        (async () => {
          const rawResponse = await fetch('api/help/sendEmail', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `${token}`,
            },
            body: JSON.stringify({ email: this.state.email, name: this.state.name, message: this.state.message }),
          });
          const content = await rawResponse;
          if (content.status === 200) {
            this.setState({
              complete: true,
            });
          } else {
            toast.error('An error occured! Please try again later!', {
              className: 'toast-popup error',
            });
          }
        })();
      });
    }
  }

  public validFields() {
    if (this.state.name.length === 0) return false;
    if (this.state.message.length === 0) return false;
    if (this.state.email.length === 0 || this.state.emailError === true) return false;

    return true;
  }

  public emailSent() {
    this.props.history.push('/help/frequent-questions');
  }

  public render() {
    const {
      name,
      email,
      message,
      formIsSubmitted,
    } = this.state;
    return (
      <div>
        {this.state.complete &&
          <Modal title='Thanks for contacting us!'
            cancel={() => this.emailSent()}
            continue={() => this.emailSent()}
            useCancelButton={false}
            inProgress={false}
          > <p> We will get back to you shortly. </p>
          </Modal>
        }
        <div className='contact-form'>
          <Form title={''}>
            <h3 className="text-align-left header">Contact Us</h3>
            <p className="text-align-left">If you couldn&apos;t find an answer to your question in the    &nbsp;
              <Link className='link'
                to='/help/user-guide'>
            User Guide
              </Link>
            &nbsp;or&nbsp;
              <Link to='/help/frequent-questions' 
                className="link"
              >FAQ&apos;s 
              </Link>
           &nbsp;sections you can contact our support team through the form below.</p>
            <div className="flex-display gap">
              <div className="textbox-size">
                <Input
                  name="name"
                  label="Your Name"
                  placeholderText="Name"
                  inputType="text"  
                  onChange={(e: string) => this.handleTextInputUpdate(e, 'name')}
                  value={name}
                  valid={formIsSubmitted && name.length === 0}
                />
              </div>
              <div className="textbox-size">
                <Input
                  name="email"
                  label="Email Address"
                  placeholderText="Your email address"
                  inputType="text"
                  onChange={(e: string) => this.handleTextInputUpdate(e, 'email')}
                  value={email}
                  valid={formIsSubmitted && this.validateEmail(email)}
                />
                {this.state.emailError && this.state.email.length > 0 ? <span className="email-validate-color flex-display">Please enter a valid email address.</span> : ''}
              </div>
            </div>
            <div className="flex-display" >
              <TextArea
                name="message"
                label="Message"
                placeholderText="Message"
                onChange={(e: string) => this.handleTextInputUpdate(e, 'message')}
                value={message}
                valid={formIsSubmitted && message.length === 0}
              />
            </div>
            <Button
              text="Send"
              onClick={() => this.sendEmail()}
              enabled
            />
          </Form>
        </div>
      </div>
    );
  }
}

export default connector(withRouter(Contact));
